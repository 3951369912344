import {Injectable} from '@angular/core';
import {ApiHandlerService} from '../api-handler.service';
import {ResolveApiUrls} from '../../shared/magic-methods/resolveApiUrls';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class SharedService extends ResolveApiUrls {

  constructor(private setupService: ApiHandlerService) {
    super();
  }

}
