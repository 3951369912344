import {SharedService} from '../../services/apis/setup-service';
import {AbstractControl} from '@angular/forms';
// import {setTimeout} from 'timers';
import {CONSTANTS} from '../../utils/constants';
import * as UI from '../../shared/magic-methods/ui';

declare const $: any;


export class MagicClasses {

    constructor(public _setUP: SharedService) {
    }

    public componentTasks = {
        canEdit: true,
        canView: true,
        canToggle: true,
        canCreate: true
    }

    public filterParams: any[] = [
        // {displayName: 'Customer Account Number', columnName: 'sourceAccountNumber'},
        // {displayName: 'Customer BVN', columnName: 'sourceBVN'},
        // {displayName: 'Platform Involved', columnName: 'aggregatorPlatformInvolved'},
        // {displayName: 'Fraud Channel', columnName: 'fraudChannel'},
        // {displayName: 'Customer Bank', columnName: 'sourceBank'}
    ];

    public fileContainer = {
        show: false
    };

    public maxDateIsToday = new Date().toISOString().split('T')[0];

    public templateDownload = false;


    public showCards = {
        create: false
    };


    public loaders = {
        processing: false
    };

    public messages = {
        create: 'Save',
        update: 'Update'
    };


    public userTypes = CONSTANTS.USER_TYPES;


    public resetLoaderAndMessage() {
        this.loaders.processing = !this.loaders.processing;
        this.messages.create = (this.loaders.processing) ? 'Saving...' : 'Save';
        this.messages.update = (this.loaders.processing) ? 'Updating...' : 'Update';
    }

    public setStatusBadge(status): string {
        return (status) ? 'success' : 'danger';
    }

    public setStatusName(status): string {
        return (status) ? 'On' : 'Off';

    }

    public triggerModalOrOverlay(action: string, modalId: string, modalIsStatic?: string) {
        if (modalIsStatic) {
            $(`#${modalId}`).modal({
                backdrop: 'static',
                keyboard: false
            });
        }
        (action === 'open') ? $(`#${modalId}`).modal('show') : $(`#${modalId}`).modal('hide');
        // (action === "open") ? this.overlay.open(modalId, 'slideInLeft') : this.overlay.close(modalId, () => {
        // });
    }

    public selectWhereId(data: any[], key, id) {
        const dataItem: any[] = [];
        data.forEach(item => {
            const itemKey = parseInt(item[key], 10);
            const itemId = parseInt(id, 10);
            if (itemKey === itemId) {
                dataItem.push(item);
            }
        });
        return dataItem[0];
    }

    // public processEntity(entityObject, action, data?: any, entityId?){
    //     return this._setUP.processEntity(entityObject, action, data || null, entityId || null);
    // }

    public processErrors(error): string {
        let errorBody = '';
        const errors = (error['error']) ? error['error']['errors'] : {msg: 'Invalid'};
        if (errors) {
            for (const key in errors) {
                errorBody += errors[key].toString() + '<br>';
            }
        } else if (error['message']) {
            errorBody = error['message'];
        }
        return errorBody;
    }

    public hasRequiredField(abstractControl: AbstractControl): boolean {
        if (abstractControl.validator) {
            const validator = abstractControl['validator']({}as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
        if (abstractControl['controls']) {
            for (const controlName in abstractControl['controls']) {
                if (abstractControl['controls'][controlName]) {
                    if (this.hasRequiredField(abstractControl['controls'][controlName])) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    public setValidationClass(formStatus) {
        return {
            'btn-primary': !formStatus,
            'btn-success': formStatus
        };
    }

    public toggleDiv(cardId, key = 'create') {
        if (!this.showCards[key]) {
            $(`#${cardId}`).removeClass('zoomOut');
            $(`#${cardId}`).addClass('zoomIn');

            setTimeout(() => {
                this.showCards[key] = !this.showCards[key];
            }, 2);
            return;
        }
        $(`#${cardId}`).removeClass('zoomIn');
        $(`#${cardId}`).addClass('zoomOut');

        setTimeout(() => {
            this.showCards[key] = !this.showCards[key];
        }, 500);

    }

    /**
     * This method resets all cards triggered in a component.
     * It is usually called on destroy
     */
    public resetShowCards(): void {
        for (const key in this.showCards) {
            this.showCards[key] = false;
        }
    }

    /**
     * This method initializes the card(s) to be triggered in a component
     * @param {string | string[]} showcards
     */
    public initShowCards(cards: string | string[]): void {
        if (typeof cards === 'string') {
            this.showCards[cards] = false;
        } else {
            cards.forEach(card => this.showCards[card] = false);
        }
    }

    /**
     * This method fakes a backend API call using a timeout and implements the user's callBack
     * @param callBack
     */
    public fakeBackendCall(callBack?: any) {
        this.resetLoaderAndMessage();
        setTimeout(() => {
            this.resetLoaderAndMessage();
            if (callBack) {
                callBack();
            }

        }, 2000);
    }

    public convertObjectToArray(obj = 'usertype'): string[] {

        const convertedArray = [];
        if (obj === 'usertype') {
            for (const key in this.userTypes) {
                convertedArray.push(this.userTypes[key]);
            }
        }
        return convertedArray;

    }

    public deepCopy(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    public scrollIntoView(location, el, timeOut = 1000) {
        if (location) {
            setTimeout(() => {
                el.nativeElement.querySelector(location).scrollIntoView({behavior: 'smooth'});
            }, timeOut);
        }

    }

    public downloadTemplate(buttonId: string) {
        this.templateDownload = true;
        const downloadButton = $(`#${buttonId}`);
        downloadButton[0].click();
        setTimeout(() => {
            this.templateDownload = false;
        }, 3000);
    }

    public openFileContainer() {
        this.fileContainer.show = !this.fileContainer.show;
    }

    public checkEditable(entityArray: any[], splitAndKeys?): any[] {
        const presentMonth = new Date().getMonth();
        entityArray.forEach((record) => {
            const monthCreated = record['created_at'] || record['createdAt'];
            if (monthCreated) {
                const dateCreatedMonth = new Date(monthCreated).getMonth();
                if (presentMonth !== dateCreatedMonth) {
                    record['not_editable'] = true;
                }
            }
            if (splitAndKeys) {
                for (const key in splitAndKeys) {
                    if (key !== 'split') {
                        record[key] = this.formaatDate(record[key], splitAndKeys['split']);
                    }
                }

            }
        });
        return entityArray;
    }


    public scrollIntoEditView(divId = 'updateCard') {
        setTimeout(() => {
            const element = document.getElementById(`${divId}`);
            element.scrollIntoView({block: 'end', behavior: 'smooth'});
        }, 2);
    }

    public formaatDate(date: string, T?): string {
        return (!T) ? date.split(' ')[0] : date.split(T)[0];
        // .split('-').join('/') : date;
    }

    // public getMaxDate():string{
    //     const getToday = ;
    //     console.log("GET TODAY",getToday);
    //     return getToday;
    // }
    public isNum(val: string, inputId, isAmount = false) {
        const isANumber = /^\d+$/.test(val);
        const inputElement = `#${inputId}`;
        const isDot = (!isAmount) ? isAmount : /\./.test(val) && val.split('.').length === 2;
        if (!isANumber && !isDot) {
            const newValue = val.slice(0, (val.length - 1));
            $(inputElement).val(null);
            $(inputElement).val(newValue);
        }
    }

    public setBadgeClass(active): string {
        return active ? 'badge badge-success' : 'badge badge-danger';
    }

    public refreshTable(id: string) {
        const tableId = `#${id}`;
        UI.destroy(tableId);
        setTimeout(() => {
            UI.run(tableId);
        }, 200);
    }

    public orderAlphabetically(array: any[]) {
        return array.sort((a, b) => {
            return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
        });
    }
}
