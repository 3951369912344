import {Injectable} from '@angular/core';
import {ApiHandlerService} from '../api-handler.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {TokenService} from '../token.service';

@Injectable()
export class NipDisputeService {

    constructor(private apiHandler: ApiHandlerService,
                private token: TokenService) {
    }

    public NIP_DRS_URL = environment.API_URL.base_nip_drs;

    /**
     * @Desc Get dashboard details
     * @returns {Observable<any>}
     */
    public getAllDashboard = (): Observable<any> => {
        return this.apiHandler.get(`${this.NIP_DRS_URL + environment.API_URL.dispute_dashboard}`);
    }

    /**
     * @Desc Get transactions
     * @returns {Observable<any>}
     */
    public getTransactions = (transactionStatus: string, loggedByMyInstitution: boolean, sessionId: string, pageNumber: number, pageSize: number): Observable<any> => {

      let extra = '';

      if (typeof transactionStatus !== 'undefined' && transactionStatus) {
        extra = extra + 'transactionStatus=' + transactionStatus + '&';
      }

      if (typeof loggedByMyInstitution !== 'undefined' && loggedByMyInstitution) {
        extra = extra + 'loggedByMe=' + loggedByMyInstitution + '&';
      }

      if (typeof sessionId !== 'undefined' && sessionId) {
        extra = extra + 'sessionId=' + sessionId + '&';
      }

      if (typeof pageNumber !== 'undefined' && pageNumber) {
        extra = extra + 'pageNumber=' + pageNumber + '&';
      }

      if (typeof pageSize !== 'undefined' && pageSize) {
        extra = extra + 'pageSize=' + pageSize + '&';
      }

      if (typeof extra !== 'undefined' && extra) {
        extra = '?' + extra;
      }

        return this.apiHandler.get(`${this.NIP_DRS_URL + environment.API_URL.dispute_transactions + extra}`);
    }

    /**
     * @Desc update dispute transaction as ACKNOWLEDGED or DENIED
     * @param transactionUpdateObject
     * @returns {Observable<any>}
     */
    public updateLoggedDisputeTransaction = (transactionUpdateObject: any): Observable<any> => {
      return this.apiHandler.put(`${this.NIP_DRS_URL + environment.API_URL.dispute_transactions }`, transactionUpdateObject);
    }

}

