// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_KEY: '',
  API_URL: {

    // base: 'http://127.0.0.1:',
    //base: 'http://10.7.7.75:',
	//base: '${window.location.protocol}//${window.location.host}:',
    base_auth: '8762/auth/',
    base_admin: '8762/admin/',
    base_nip_drs: '8762/nip/',
    login: 'login',
    user: 'users',
    institution: 'institutions',
    audit: 'audit',
    password: 'password/update-password',
    reset_password: 'password/reset',
    reset_validate_recovery_code: 'password/validate-recovery-code',
    reset_password_update: 'password/update',
    nip_report: 'nipreport',
    dispute_transactions: 'transactions/dispute',
    dispute_dashboard: 'transactions/dispute/dashboard',
    reversal_transactions: 'transactions/reversal',
    reversal_dashboard: 'transactions/reversal/dashboard'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
