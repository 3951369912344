import {Injectable} from '@angular/core';
import {ApiHandlerService} from '../api-handler.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class UsersInstitution {

  private _institutions: any;

  constructor(private apiHandler: ApiHandlerService) { }

  public INSTITUTION_URL = environment.API_URL.base_admin + environment.API_URL.institution;

  /**
   * Get all institutions
   * @returns {Observable<any>}
   */
  public getAllInstitutions = (): Observable<any> => {
    return this.apiHandler.get(`${this.INSTITUTION_URL}`);
  }

  /**
   * create new user function
   * @param institutionObject
   * @returns {Observable<any>}
   */
  public createInstitution = (institutionObject: any): Observable<any> => {
    return this.apiHandler.post(`${this.INSTITUTION_URL}`, institutionObject);
  }

  /**
   * @Desc Update user institution
   * @param data
   * @returns {Observable<any>}
   */
  public updateInstitution = (data: any): Observable<any> => {
    return this.apiHandler.put(`${this.INSTITUTION_URL}`, data);
  }

  get institutions(): any {
    if (this._institutions === null) {
      this.apiHandler.get(`${this.INSTITUTION_URL}`).subscribe((response) => {
        this._institutions = response;
      }, (error) => {
        this._institutions = null;
      });
    }

    return this._institutions;
  }

  set institutions(value: any) {
    this._institutions = value;
  }

}
