import {TokenService} from '../services/token.service';
import {DateFormatting} from './util';
import {environment} from '../environments/environment';
import {Cache} from './cache';
import {HttpHeaders} from "@angular/common/http";


/**
 *  Environment type configuration
 */


export class ApiConfig extends DateFormatting {


    static CacheHostname() {
        //return environment.API_URL.base;
		const hostname = window.location.hostname
        const protocol = window.location.protocol
        const baseurls = protocol+'//'+hostname+':';
        return baseurls
    }

    static API_DEFAULT_URL = ApiConfig.CacheHostname();
    static API_SANS_URL = environment.API_URL.base_auth;
    static API_FRAUD_URL = environment.API_URL.base_admin;
    static API_CAE_URL = environment.API_URL.base_nip_drs;
    static API_KEY = environment.API_KEY;
    public headers = {headers: this.setHeaders()};
    public authToken: any;

    constructor(private myTokenService: TokenService) {
        super();
    }

    /**
     * This is used to Set Headers on before request
     * @returns {Headers}
     */
    public setHeaders(): HttpHeaders {
        this.authToken = this.myTokenService.getAuthUser();
        const headersConfig = new HttpHeaders();
        headersConfig['Content-Type'] = 'application/json';
        headersConfig['Accept'] = 'application/json';

        if (this.myTokenService.isLoggedIn()) {
            headersConfig['Authorization'] = `${this.myTokenService.getAuthUserToken()}`;
        }
        if (ApiConfig.API_KEY) {
            headersConfig['API-KEY'] = ApiConfig.API_KEY;
        }
        // if (ApiConfig.API_KEY) {
        //   headersConfig['API-KEY'] = ApiConfig.API_KEY;
        // }

        return headersConfig;
    }


    public setFileHeaders(): HttpHeaders {
        this.authToken = this.myTokenService.getAuthUser();
        const headersConfig = new HttpHeaders();
        headersConfig['Content-Type'] = 'application/octet-stream';
        headersConfig['Accept'] = 'application/octet-stream';
        // headersConfig.append('Accept', 'application/json');
        //
        if (this.myTokenService.isLoggedIn()) {
            headersConfig['Authorization'] = `${this.myTokenService.getAuthUserToken()}`;
        }
        if (ApiConfig.API_KEY) {
            headersConfig['API-KEY'] = ApiConfig.API_KEY;
        }
        // if (ApiConfig.API_KEY) {
        //   headersConfig['API-KEY'] = ApiConfig.API_KEY;
        // }

        return headersConfig;
    }


}

