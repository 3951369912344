import { Component, OnInit } from '@angular/core';
import {MagicClasses} from '../../../shared/magic-methods/classes';
import {SharedService} from '../../../services/apis/setup-service';
import {Router} from '@angular/router';
import {NotificationService} from '../../../services/notification.service';
import {TokenService} from '../../../services/token.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {FormBuilder} from '@angular/forms';
import {Cache} from '../../../utils/cache';
import {UsersService} from '../../../services/apis/users.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent extends MagicClasses implements OnInit {

  institutionName: string;
  isNotSuperAdminUser: boolean;
  changePassword: boolean;

  userDetail: any;
  userId;
  role;
  closeResult: string;

  constructor(private _sharedService: SharedService,
              private formBuilder: FormBuilder,
              private _router: Router,
              private  Alert: NotificationService,
              private _tokenService: TokenService,
              private _authService: AuthenticationService,
              private _userService: UsersService,
              private modalService: NgbModal) {
    super(_sharedService);
  }

  ngOnInit() {

    this.institutionName = Cache.get('USER').institutionName;
    this.userId = Cache.get('USER').userId;
    this.role = Cache.get('USER').role;
    this.isNotSuperAdminUser = this.institutionName !== 'NIBSS';
    this.changePassword = Cache.get('USER').changePassword;

  }

  public logoutUser() {
    this._authService.logout();
    this._router.navigateByUrl('/');
  }

  public routeByUrl(url: string) {
    this._router.navigateByUrl(url);
  }

  public getUserDetail() {

    this._userService.getUserById(this.userId).subscribe((response) => {

      this.userDetail = response;

    }, (error) => {
      if (error.status === 0) {
        this.Alert.error('Please check your INTERNET CONNECTION, and try again');
      } else {
        this.Alert.error('Unable to change password', error);
      }
    });
  }

  public open(content) {

    // loading the content to the modal
    this.getUserDetail();

    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
