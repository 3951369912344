import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './_guards';

const routes: Routes = [
  {path: '', loadChildren: './authentication/authentication.module#AuthenticationModule'},
  {path: 'admin', loadChildren: './admin/admin.module#AdminModule', canActivate: [AuthGuard]},
  {path: 'nip', loadChildren: './nip-drs/nip-drs.module#NipDrsModule', canActivate: [AuthGuard]},
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
