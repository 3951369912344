import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {TokenService} from './token.service';
import {environment} from '../environments/environment';
import {NotificationService} from './notification.service';

@Injectable()
export class ApiInterceptorService implements HttpInterceptor {

    constructor(private Alert: NotificationService, private tokenService: TokenService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const setHeaders = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        };

        if (req.headers['Content-Type'] !== 'application/json') {
            delete setHeaders['Content-Type'];
        }

        req = req.clone({setHeaders});

        if (this.tokenService.isLoggedIn()) {
            req = req.clone({headers: req.headers.set('Authorization', this.tokenService.getAuthUserToken())});
        }

        return next.handle(req);
    }

}
