declare const $: any;
import {TokenService} from '../../services/token.service';

export const run = (tableTitle = '') => {
    $(document).ready(() => {
        $('#datatable').DataTable();

        const table = $(`#${tableTitle}`).DataTable({
            lengthChange: false,
            buttons: ['pageLength', 'colvis',
                {
                    extend: 'excel',
                    title: () => {
                        return `Antifraud_${tableTitle.toLowerCase()}`;
                    },
                    exportOptions: {
                        columns: 'th:not(:last-child)'
                    }
                },
                {
                    extend: 'print',
                    title: () => {
                        return `Antifraud ${tableTitle}`;
                    },
                    exportOptions: {
                        columns: 'th:not(:last-child)'
                    }
                },
                {
                    extend: 'pdf',
                    title: () => {
                        return `Antifraud ${tableTitle}`;
                    },
                    exportOptions: {
                        columns: 'th:not(:last-child)'
                    }
                },
                {
                    extend: 'csv',
                    title: () => {
                        return `Antifraud ${tableTitle}`;
                    },
                    exportOptions: {
                        columns: 'th:not(:last-child)'
                    }
                }
            ],
        });

        table.buttons().container()
            .appendTo(`#${tableTitle}_wrapper .col-md-6:eq(0)`);
    });
};


export const destroy = (tableTitle = '') => {

    $(document).ready(() => {
        if ($.fn.DataTable.isDataTable(`#${tableTitle}`)) {
            $(`#${tableTitle}`).DataTable().destroy();
        }

    });
};


export const ajaxRun = (tableTitle = '', token) => {
        let requestData;
        $('#Users').DataTable({
            'serverSide': true,
            'processing': true,
            // 'paging': true,
            // 'searching': {'regex': true},
            // 'lengthMenu': [[10, 25, 50, 100, -1], [10, 25, 50, 100, 'All']],
            'pageLength': 10,
            'ajax': {
                'type': 'GET',
                'beforeSend': function (request) {
                    request.setRequestHeader('Authorization', token);
                    // requestData = request;
                    // console.log(" ", requestData.status);
                },
                'url': 'http://197.255.63.146:9090/admin/users',
                'dataType': 'json',
                'contentType': 'application/json; charset=utf-8',
                'data': function (data) {
                    // Grab form values containing user options
                    // const form = {};
                    // $.each($('form').serializeArray(), function (i, field) {
                    //     form[field.name] = field.value || '';
                    // });
                    // Add options used by Datatables
                    // const info = {'start': 0, 'length': 10, 'draw': 1};
                    // $.extend(form, info);
                    console.log('Dadadaddata', data);
                    // return JSON.stringify(form);
                },
                'complete': function (response) {
                    alert(requestData);
                    console.log(response);
                }
            }
        });
    }

;
