import {Injectable} from '@angular/core';
import {ApiHandlerService} from '../api-handler.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {TokenService} from '../token.service';

@Injectable()
export class UsersService {

    constructor(private apiHandler: ApiHandlerService,
                private token: TokenService) {
    }

    public USER_URL = environment.API_URL.base_admin + environment.API_URL.user;
    public PASSWORD_URL = environment.API_URL.base_admin + environment.API_URL.password;

    /**
     * @Desc Get all users function
     * @returns {Observable<any>}
     */
    public getAllUsers = (param: string, pageNumber: number, pageSize: number): Observable<any> => {

      let extra = '';

      if (typeof param !== 'undefined' && param) {
        extra = extra + 'param=' + param + '&';
      }

      if (typeof pageNumber !== 'undefined' && pageNumber) {
        extra = extra + 'pageNumber=' + pageNumber + '&';
      }

      if (typeof pageSize !== 'undefined' && pageSize) {
        extra = extra + 'pageSize=' + pageSize + '&';
      }

      if (typeof extra !== 'undefined' && extra) {
        extra = '?' + extra;
      }

      return this.apiHandler.get(`${this.USER_URL + extra}`);
    }

    /**
     * @Desc create new user function
     * @param userObject
     * @returns {Observable<any>}
     */
    public createUsers = (userObject: any): Observable<any> => {
        return this.apiHandler.post(`${this.USER_URL}`, userObject);
    }

    /**
     * @Desc Update selected user
     * @param userObject
     * @returns {Observable<any>}
     */
    public updateUsers = (userObject: any): Observable<any> => {
      return this.apiHandler.put(`${this.USER_URL}`, userObject);
    }

    /**
     * @Desc Toggle user status
     * @param {number} userId
     * @returns {Observable<any>}
     */
    public toggleUser = (userId: number): Observable<any> => {
        const body = {id: userId};
        return this.apiHandler.put(`${this.USER_URL}/toggle`, body);
    }

    /**
     * @Desc Get userById
     * @param {number} userId
     * @returns {Observable<any>}
     */
    public getUserById = (userId: number): Observable<any> => {
        return this.apiHandler.get(`${this.USER_URL}/${userId}`);
    }

    /**
     *
     * @param data
     * @returns {any}
     */
    public changeUserPassword = (data): any => {
        // delete data['confirm_password'];
        return this.apiHandler.put(`${this.PASSWORD_URL}`, data);
    }
}

