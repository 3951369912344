import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {NotificationModule} from '../shared/modules/notification.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgbDropdown, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ApiInterceptorService} from '../services/api-interceptor.service';
import {NotificationService} from '../services/notification.service';
import {ValidationErrorService} from '../services/validation-error.service';
import {EventsService} from '../services/event.service';
import {SharedService} from '../services/apis/setup-service';
import {ApiHandlerService} from '../services/api-handler.service';
import {ScriptLoaderService} from '../services/script-loader.service';
import {UserPermissionsService} from '../services/apis/user-permissions.service';
import {TokenService} from '../services/token.service';
import {AuthenticationService} from '../services/authentication.service';
import {LayoutsModule} from './layouts/layouts.module';
import {NipDisputeService} from '../services/apis/nip-dispute.service';
import {FormsModule} from '@angular/forms';
import {NipReversalService} from '../services/apis/nip-reversal.service';
import {NipSearchLogService} from '../services/apis/nip-search-log.service';
import {DatePipe} from '@angular/common';
import {UsersInstitution} from '../services/apis/user.institutions.service';
import {AutoLogoutService} from '../services/AutoLogoutService';
import {AuthGuard} from './_guards';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutsModule,
    NotificationModule,
    NgbModule,
    FormsModule,
    NgbModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptorService,
      multi: true
    },
    NotificationService,
    ValidationErrorService,
    EventsService,
    ScriptLoaderService,
    ApiHandlerService,
    SharedService,
    AuthenticationService,
    UserPermissionsService,
    ApiHandlerService,
    NipDisputeService,
    NipReversalService,
    NipSearchLogService,
    UsersInstitution,
    DatePipe,
    TokenService,
    AutoLogoutService,
    AuthGuard,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
